import { Controller } from '@hotwired/stimulus';
import Popover from 'bootstrap/js/dist/popover';

/**
 * popover controller.
 *
 * Configure Bootstrap popovers.  Most options can be passed as data attributes;
 * see https://getbootstrap.com/docs/5.1/components/popovers/#options.
 *
 * Data map options:
 * - trigger: how popover is triggered - click | hover | focus | manual.  Note
 *    that we apply some extra functionality to when popovers are shown or hidden
 *    based on this value.
 * - htmlSafe: if true, the content will be interpreted as html and will not be
 *    sanitized.
 */
export default class extends Controller {
  static targets = ['content'];

  static values = { trigger: String, htmlSafe: Boolean };

  connect() {
    this._disconnect = () => this.disconnect();
    document.addEventListener('turbolinks:before-cache', this._disconnect);

    this.$element = $(this.element);

    const options = {};

    if (this.htmlSafeValue) {
      options.sanitize = false;
      options.html = true;
    }

    if (this.hasTriggerValue) {
      options.trigger = this.triggerValue;
    }

    if (this.triggerValue === 'hover') {
      // Use the popover trigger as the popover's container element.  Otherwise,
      // if there are nested popovers and the user hovers over the child popover,
      // the parent popover mouseout would trigger and the hover check on it would
      // return false and so to close the whole popover hierarchy.
      options.container = this.element;
    }

    this.popover = new Popover(this.element, options);

    // If triggering the popover on click, hide the popover when a click occurs
    // outside of it.
    //
    // Note that Bootstrap suggests using the { target: 'focus' } option for
    // this purpose, but has problems, like it dismisses the popover when
    // clicking anything inside it. A better solution may result from
    // https://github.com/twbs/bootstrap/issues/31401.
    if (this.triggerValue === 'click') {
      this._hideOnClickOutsideHandler =
        this.hideOnClickOutsideHandler.bind(this);

      this.$element.on('shown.bs.popover', () => {
        document.body.addEventListener(
          'click',
          this._hideOnClickOutsideHandler,
        );
      });

      this.$element.on('hidden.bs.popover', () => {
        document.body.removeEventListener(
          'click',
          this._hideOnClickOutsideHandler,
        );
      });
    }
  }

  disconnect() {
    this.$element.off('.popover');
    this.$element.off('.bs.popover');

    // This does not actually properly dispose of the popovers.
    // Bootstrap's tooltip library (from which popover descends) adds jquery
    // data to the element, and relies on the presence of that data to properly
    // dispose of the tooltip (e.g. to clear timeouts).  However, that jquery
    // data is gone by the time the mutation observer calls this disconnect method.
    //
    // For turbolinks navigation, this issue is mitigated by adding a
    // turbolinks:before-cache event listener which ensures open popovers are
    // cleared prior to storing a cache a the DOM.
    this.popover?.dispose();
    delete this.popover;

    document.removeEventListener('turbolinks:before-cache', this._disconnect);
  }

  hideOnClickOutsideHandler(event) {
    if (
      !this.$element.is(event.target) && // for buttons that trigger popovers
      this.$element.has(event.target).length === 0 && // for icons within a button that triggers a popover
      $('.popover').has(event.target).length === 0 // for any element within a popover
    ) {
      this.popover.hide();
    }
  }
}
